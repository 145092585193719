.home {
  .container {
    position: relative;
  }

  .main {
    padding: 0 150px;
  }

  .site-nav {
    position: absolute;
    top: 100px;

    li {
      display: block;
    }
  }
}

.homepage-image {
  width: 100%;
}

@media (max-width: 850px) {
  .home {
    .site-nav {
      position: static;
    }

    .main {
      margin-bottom: 40px;
      padding: 0;
    }
  }
}


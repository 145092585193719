.container {
  padding: 40px;
  max-width: 960px;
  margin: 0 auto;
}

.site-header,
.main {
  margin-top: 40px;
}

.section-header {
  margin: 40px 0;
}

.site-nav {
  li {
    display: inline-block;
    margin: 10px 0;
  }

  a {
    border: 1px solid $text-color;
    font-style: normal;
    font-size: 1em;
    font-weight: 700;
    padding: 5px 10px;
    text-transform: uppercase;
    -webkit-transition: color .3s, background-color .3s;
    transition: color .3s, background-color .3s;

    &:hover {
      border: 1px solid $text-color;
      background-color: $text-color;
      color: $background-color;
    }
  }
}

@media (min-width: 600px) {
  .site-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .site-nav {
    margin-top: 0;

    a {
      font-size: 1.2em;
    }
  }
}

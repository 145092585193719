* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: $background-color;
  color: $text-color;
  margin: 0;
  padding: 0;
  font-family: $font-family;
  font-size: 16px;
  letter-spacing: .5px;
  line-height: 1.5;
}

ul, ol {
  padding: 0;
}

li {
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family;
  font-weight: 700;
  text-transform: uppercase;
}

a, a:hover, a:active, a:visited, a:focus {
  color: $text-color;
  text-decoration: none;
}

.clear:after {
  content: "";
  display: table;
  clear: both;
}

